<messages>["./Zone"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->
<template>
  <base-layout mw1>
    <v-col cols="12">
      <v-alert
        type="error"
        :value="!isLoadingData && !zone">
        {{ error }}
      </v-alert>

      <v-card v-if="isLoadingData || zone">
        <v-container
          v-if="isLoadingData"
          key="loading">
          <v-row>
            <v-progress-linear
              indeterminate
              color="primary"/>
          </v-row>
        </v-container>
        <template v-if="zone && !isLoadingData">
          <v-card-title primary-title class="py-0">
            <div>
              <div>
                <span :class="{headline: true, deletedItemHeadline: zone.currentVersionDeleted}">
                  {{ zone.domainName }}
                </span>
                <template
                  v-if="zone.encodedDomainName &&
                    zone.encodedDomainName !== zone.domainName">
                  (<span
                    class="cgwng-subheading"
                    v-text="`${zone.encodedDomainName}`"/>)
                </template>
              </div>
              <div class="cgwng-subheading">
                {{ zone.handle }}
              </div>
            </div>
          </v-card-title>

          <v-tabs
            v-model="activeTab"
            right
            slider-color="primary">
            <v-tab
              key="current"
              v-t="'general.history.current'"
              :disabled="zone.currentVersionDeleted"/>
            <v-tab-item key="current">
              <v-card-text class="pt-0">
                <zone-data :zone="zone"/>
              </v-card-text>
              <v-card-actions>
                <v-spacer/>
                <back-button/>
                <template v-if="isManageable">
                  <v-btn
                    v-t="'general.button.edit'"
                    :to="{name: 'zone.edit', params: {zoneId: zone.handle}}"
                    color="primary"/>
                </template>
              </v-card-actions>
            </v-tab-item>
            <v-tab
              key="historic"
              v-t="'general.history.historic'"/>
            <v-tab-item key="historic">
              <v-card-text class="pt-2">
                <template v-if="isLoadingHistory">
                  <v-row>
                    <v-progress-linear
                      indeterminate
                      color="primary"/>
                  </v-row>
                </template>
                <history-panel
                  v-for="(data, idx) in historicObjectData"
                  v-else
                  :id="'version-' + data.versionId"
                  :key="data.versionId"
                  :expanded="isHistoryPanelExpanded (idx)"
                  :seq-number="idx"
                  @expand="assignHistoricZone (data, idx)">
                  <template #label>
                    <span v-text="idx + 1"/>
                  </template>
                  <template #start>
                    <span v-text="formatDate (data.from)"/>
                  </template>
                  <template #end>
                    <span v-text="data.to ? formatDate (data.to) : $t ('general.history.now')"/>
                  </template>
                  <template #operation>
                    <order-type :name="data.orderType"/>
                  </template>
                  <template #content>
                    <zone-data
                      v-if="!data.isLoading && data.zone"
                      history
                      class="ma-0"
                      :zone="data.zone"/>
                    <v-progress-linear
                      v-else
                      indeterminate
                      color="primary"/>
                  </template>
                </history-panel>
              </v-card-text>
            </v-tab-item>
          </v-tabs>
        </template>
      </v-card>
    </v-col>
  </base-layout>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import {mapActions, mapGetters} from 'vuex'

  import RegistryObjectView, {HISTORIC}
    from '@/app/core/mixins/RegistryObjectViewHelper'

  import BaseLayout from '@/app/core/components/BaseLayout'
  import HistoryPanel from '@/app/core/components/HistoryPanel'
  import OrderType from '@/app/core/components/OrderType'
  import BackButton from '@/app/core/components/BackButton'

  import ZoneData from './components/ZoneData'

  export default {
    name: 'ZoneView',

    components: {
      BaseLayout,
      ZoneData,
      HistoryPanel,
      OrderType,
      BackButton
    },

    mixins: [RegistryObjectView],

    props: {
      name: {
        type: String,
        default: undefined
      }
    },

    data () {
      return {
        zone: null,
        isLoadingHistory: false,
        isLoadingData: true
      }
    },

    computed: {
      ...mapGetters ({
        mayManageObject: 'auth/mayManageObject'
      }),

      loadParams () {
        return {
          id: this.id,
          vid: this.vid,
          name: this.name
        }
      },

      error () {
        const routeParams = this.$route.params

        if (routeParams.name) {
          return this.$t ('view.invalidZone.name', {name: routeParams.name})
        } else {
          return this.$t ('view.invalidZone.id', {id: routeParams.vid})
        }
      },

      isManageable () {
        return this.mayManageObject (this.zone.clientId)
      }
    },

    created () {
      this.loadZoneByProps ()
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      loadZone (params) {
        this.isLoadingData = true

        const op = params.vid ? 'zone/loadVersion' : 'zone/load'

        const request = {
          op,
          params,
          cb: data => {
            this.zone = {...data.zoneData, ...data.viewData}
            this.setObjectId (this.zone.id)

            if (params.vid || this.zone.currentVersionDeleted) {
              this.setObjectVersionId (this.zone.versionId)
              this.setActiveTab (HISTORIC)
            }

            this.loadHistory (this.zone.versionId)
          },
          cbError: () => {},
          cbFinal: () => {
            this.isLoading = false
            this.isLoadingData = false
          }
        }

        this.fetchData (request)
      },

      loadHistory (id) {
        this.isLoadingHistory = true
        return this.fetchData ({
          op: 'zone/history',
          params: {id},
          cb: data => {
            this.historicObjectData = data.historyData.map ((item) => {
              return {...item, isLoading: false, zone: null}
            })

            this.expandHistoryPanelWithLoadedVersion ()
          },
          cbFinal: () => {
            this.isLoadingData = false
            this.isLoadingHistory = false
          }
        })
      },

      loadZoneByProps () {
        this.loadZone (this.loadParams)
      },

      assignHistoricZone (zoneData, index) {
        this.setObjectVersionId (zoneData.versionId)

        if (index === 0) {
          zoneData.zone = this.zone
        } else if (!zoneData.zone) {
          this.isLoading = true
          zoneData.isLoading = true

          this.fetchData ({
            op: 'zone/loadVersion',
            params: {
              vid: zoneData.versionId
            },
            cb: data => {
              zoneData.zone = {...data.zoneData, ...data.viewData}
            },
            cbFinal: () => {
              zoneData.isLoading = false
              this.isLoading = false
              this.navigateToCurrentVersion ()
            }
          })
        }
        this.navigateToCurrentVersion ()
      }
    }
  }
</script>
