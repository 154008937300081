<template>
  <router-link
    v-if="mayViewDomain"
    :class="{ deletedItemLink: !isActive }"
    :to="{name: 'domain.view.version', params: {vid}}"
    v-text="domainName || vid"/>
  <span v-else>
    {{ domainName || vid }}
  </span>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex'

  export default {
    name: 'DomainLink',

    props: {
      vid: {
        type: Number,
        required: true
      }
    },

    data () {
      return {
        domainName: '',
        domainClientId: '',
        isActive: true
      }
    },

    computed: {
      ...mapGetters ({
        mayViewObject: 'auth/mayViewObject'
      }),

      mayViewDomain () {
        return this.mayViewObject (this.domainClientId)
      }
    },

    watch: {
      vid (newValue) {
        this.fetchDomainData (newValue)
      }
    },

    created () {
      this.fetchDomainData (this.vid)
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      /**
       * Fetch the data of the domain with the given version ID
       *
       * @param {Number} vid     the domain version ID
       */
      fetchDomainData (vid) {
        this.fetchData ({
          op: 'domain/loadVersion',
          params: {
            vid
          },
          cb: data => {
            this.domainClientId = data.domainData.clientId
            this.domainName = data.domainData.name
            this.isActive = !data.domainData.deleted
          }
        })
      }
    }
  }
</script>
