<messages>["../Zone"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->

<template>
  <v-row>
    <!-- "show order" button -->
    <v-col cols="12" class="pt-2 pb-1 text-right" align-self="center">
      <order-dialog
        :order-id="zone.orderId"
        :object-id="zone.domainName"
        type="zone">
        <template #activator="{ on }">
          <v-btn
            small
            class="elevation-0"
            v-on="on">
            {{ history ? $t ('general.button.showOrder') :
              $t ('general.button.showCurrentOrder') }}
          </v-btn>
        </template>
      </order-dialog>
    </v-col>

    <v-col
      v-for="item in data"
      :key="item.name"
      class="d-flex child-flex"
      cols="12" md="6" xl="4">
      <data-card :title="$t (`view.section.${item.name}`)">
        <name-value-block :items="item.values"/>
      </data-card>
    </v-col>
  </v-row>
</template>

<script>
  import {EmptyMark} from '@/app/utils/string'

  import NameValueBlock from '@/app/core/components/NameValueBlock'
  import OrderDialog from '@/app/core/components/Order/OrderDialog.vue'
  import ClientLink from '@/app/core/components/ClientLink'
  import DomainLink from '@/app/core/components/DomainLink'
  import SecretText from '@/app/core/components/SecretText'
  import DataCard from '@/app/core/components/DataCard'

  import {mapActions} from 'vuex'

  export default {
    name: 'ZoneData',

    components: {
      NameValueBlock,
      OrderDialog,
      DataCard
    },

    props: {
      zone: {
        type: Object,
        required: true
      },
      history: {
        type: Boolean,
        default: false
      }
    },

    data () {
      return {
        transferServers: undefined,
        isLoading: false
      }
    },

    computed: {
      data () {
        return [
          {
            name: 'basicData',
            values: this.basicData
          },
          {
            name: 'dates',
            values: this.dates
          },
          {
            name: 'nameserver',
            values: this.nameserver
          },
          {
            name: 'providerchains',
            values: this.providerchains
          },
          ...this.zone.resourceRecords
            ? this.resourceRecords.length > 0
              ? [{
                name: 'resourceRecords',
                values: this.resourceRecords
              }]
              : []
            : [],
          ...this.zone.sourceData
            ? [{
              name: 'sourceData',
              values: this.sourceData
            }]
            : [],
          {
            name: 'dnssec',
            values: this.dnssecData
          }
        ]
      },

      basicData () {
        return [
          ...['userDefinedId', 'registryId']
            .map ((key) => {
              return {
                name: this.$t (`view.label.${key}`),
                value: this.zone[key] ? this.zone[key] : EmptyMark
              }
            }),

          ...['creatorMemberId', 'updaterMemberId']
            .map ((key) => {
              return {
                name: this.$t (`view.label.${key}`),
                value: !!this.zone[key] || EmptyMark,
                ...(
                  this.zone[key]
                    ? {
                      slotValue: this.$createElement (ClientLink, {
                        props: {
                          id: this.zone[key]
                        }
                      })
                    }
                    : {}
                )
              }
            }),

          ...[{
            name: this.$t ('view.label.type'),
            value: this.zone.sourceDataString
              ? this.$t ('view.label.source')
              : this.$t ('view.label.literal')
          }],

          ...[{
            name: this.$t ('view.label.notifications'),
            value: this.zone.notifications?.length
              ? this.zone.notifications
                .reduce ((acc, n) => {
                  return acc === '' ? n : acc + ', ' + n
                }, '')
              : EmptyMark
          }],

          {
            name: this.$t ('view.label.linkedDomain'),
            value: this.zone.linkedDomainVersionId,
            slotValue: this.$createElement (DomainLink, {
              props: {
                vid: this.zone.linkedDomainVersionId
              }
            })
          }
        ]
      },

      dates () {
        return [
          {
            name: this.$t ('view.label.creationDate'),
            value: this.zone.creationDate
              ? this.formatDate (this.zone.creationDate)
              : EmptyMark
          },
          {
            name: this.$t ('view.label.updateDate'),
            value: this.zone.updateDate
              ? this.formatDate (this.zone.updateDate)
              : EmptyMark
          }
        ]
      },

      nameserver () {
        return this.zone.nameServers.map ((ns) => ({
          name: ns,
          value: ' '
        }))
      },

      providerchains () {
        return [{
          name: this.zone.providerChainType,
          value: this.zone.providerChainSpec
        }]
      },

      resourceRecords () {
        return this.zone.resourceRecords
          ? this.zone.resourceRecords.length > 0
            ? this.zone.resourceRecords.map ((n) => {
              return {
                name: n,
                value: ' '
              }
            })
            : []
          : []
      },

      sourceData () {
        if (this.zone.sourceData) {
          return [...Object.keys (this.zone.sourceData).map ((key) => {
                    return key === 'tsigSecret' && this.zone.sourceData[key]
                      ? {
                        name: this.$t (`createUpdate.${key}`),
                        value: true,
                        slotValue: this.$createElement (SecretText, {
                          props: {
                            value: this.zone.sourceData[key],
                            copyOnly: true
                          }
                        })
                      }
                      : {
                        name: this.$t (`createUpdate.${key}`),
                        value: this.zone.sourceData[key]
                      }
                  }),
                  ...this.usedTranferServers && this.usedTranferServers.length
                    ? [{
                      name: this.$t ('view.label.transferServer.label'),
                      value: this.usedTranferServers
                    }]
                    : []
          ]
        }
        return []
      },

      dnssecData () {
        return [
          {
            name: this.$t ('view.label.dnssec'),
            value: this.zone.dnssecEnabled
          },
          {
            name: this.$t ('view.label.autoLinkage'),
            value: this.zone.autoDomainLinkage
          }
        ]
      },

      usedTranferServers () {
        const ts = []
        if (!this.transferServers) return ts
        this.zone.nameServers.forEach ((ns) => {
          if (!this.transferServers[ns]) return
          this.transferServers[ns].forEach ((t) => {
            if (!ts.includes (t)) ts.push (t)
          })
        })
        return ts
      }
    },

    created () {
      this.loadNameServers ()
    },

    methods: {
      ...mapActions ({
        getTransferServers: 'cache/getTransferServers'
      }),

      async loadNameServers () {
        this.isLoading = true
        this.transferServers = await this.getTransferServers ()
        this.isLoading = false
      }
    }
  }
</script>
